import React from 'react'
import DreamComponent from '../components/DreamComponent';
import CrawlList from '../components/CrawlList';

type Props = {}

const Search = (props: Props) => {
  return (
    <div>
      <CrawlList/>
    </div>
  )
}

export default Search