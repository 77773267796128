export async function getData(url: string): Promise<any> {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  }
  

  export async function postData(url = '', data = {}) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        const errorData = await response.json(); // Attempt to parse error details
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorData?.message || 'Unknown error'}`); // More informative error
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      // You can also throw the error further for handling in your component
      throw error;
    }
  }