import React from 'react'

import { Article } from '../types/Result';

import {Card, CardBody, Image, Button, Chip, Link, CardFooter} from "@nextui-org/react";
import RandomChips from './RandomChip';

type Props = {
    article: Article
}

 const ArticleBox : React.FC<Props> = ({
  article
 }: Props) => {

  const { featured_image, title, url, short_description, matching_keyword, matched_keyword_count } = article || {}
  return (
    <Card
      isBlurred
      className="border-none bg-background/60 dark:bg-default-100/50 w-[50%] result-single"
      shadow="sm"
    >
      <CardBody>
        <div className='flex'>
          <div className="w-40 mr-4">
            <Link isExternal href={url} showAnchorIcon>
              <Image
                alt={title}              
                isZoomed
                className="object-cover"
                shadow="md"
                src={featured_image}
              />
            </Link>
          </div>
          <div className='w-60 ml-4'>
            <h3> <Link isExternal href={url} showAnchorIcon> {title} </Link></h3>
            <p className='mb-16'> {short_description}</p>
            <Button
              href={url}
              as={Link}
              className='self-center'
              isExternal={true}
              color="primary"
              showAnchorIcon
              variant="solid"
            >
            Visit the article
            </Button>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <RandomChips title={"Matched Keywords ( " + matched_keyword_count + ")  "} chips={matching_keyword} />
      </CardFooter>
    </Card>
  );
}

export default ArticleBox;