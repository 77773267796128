import React from 'react'
import Home from './pages/Home';
import Search from './pages/Search';
import {
  Route, Routes
} from "react-router-dom";




type Props = {}

const MyRouter = (props: Props) => {
  return (
    <div className='max-w-[1024px] mx-auto'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Search />} />
      </Routes>
    </div>
  )
}

export default MyRouter;