import React from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,

} from "@nextui-org/react";
import { TableColumnType, KeyValueObject } from "../types/Component";

interface CrawlUIProps<T> {
  data: KeyValueObject<T>[]; // Generic type for data array
  tableColumns: TableColumnType[];
  pageChange?: (page: number) => void;
  uniqueKey: string;
  createCellElement: (item:T, uid: string) => React.ReactNode;
}

const TableComponent: React.FC<CrawlUIProps<any>> = ({ data, uniqueKey,
   createCellElement, pageChange, tableColumns }) => {
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 4;

  const pages = Math.ceil(data.length / rowsPerPage);

  return (
    <Table
      aria-label="react global table"
        // Only render Pagination if pageChange is provided
        bottomContent={pageChange ? (
        <div className="flex w-full justify-center">
          <Pagination
            isCompact
            showControls
            showShadow
            color="secondary"
            page={page}
            total={pages}
            onChange={(page) => pageChange(page)}
          />
        </div>
      ) : null}
      classNames={{
        wrapper: "min-h-[222px]",
      }}
    >
      <TableHeader>
        {/* Dynamically generate table columns based on data properties */}
        {tableColumns.map(({uid, name}) => (
          <TableColumn key={uid}>{name.toUpperCase()}</TableColumn>
        ))}
      </TableHeader>
      <TableBody items={data}>
            {(data) =>
              data.map((rowItem : KeyValueObject<any>) => (
                <TableRow key={rowItem[uniqueKey]}>
                  {tableColumns.map(({uid}) => (
                    <TableCell key={uid}>{createCellElement(rowItem, uid)}</TableCell>
                  ))}
                </TableRow>
              ))
            }

      </TableBody>
    </Table>
  );
};


export default TableComponent;