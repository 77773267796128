import React from 'react';
import logo from '../logo.svg';
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
} from '@nextui-org/react';
import { ChevronDown, Lock, Scale } from './Icons'; // Assuming icons are in a TS-compatible format
import ButtonTest from './ButtonTest';

type HeaderProps = {};

export default function Header(props: HeaderProps): JSX.Element {
  return (
    <Navbar>
      <NavbarBrand>
        <img src={logo} alt="Dragon Logo" className="mr-5 h-7 sm:h-9" />
        <p className="font-bold text-inherit">Dragon</p>
      </NavbarBrand>
      <NavbarContent justify="end">
        <NavbarItem className="">
          <Link  isExternal href="/api-docs"  showAnchorIcon >Try our API</Link>
        </NavbarItem>
        <NavbarItem>
          <Button as={Link}  isExternal color="primary" href="/api-redoc" variant="flat" showAnchorIcon>
            API reference Docs 
          </Button>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
}