import React from 'react';

import {Result, Article} from '../types/Result';
import ArticleBox from './ArticleBox';

import '../style/result.scss';
import DreamBox from './DreamBox';

type Props = {
    result: Result;
    relatedContent : Article[];
}

const DreamResult: React.FC<Props> = ({
    result, relatedContent = []
}) => {
  return (
    <div className='flex flex-wrap result'>
        <DreamBox {...result}/>
        {relatedContent.map((article) => (
            <ArticleBox key={article.url} article={article} />
        ))}
    </div>
  )
};

export default DreamResult;
