import React from 'react'
import {  Chip } from '@nextui-org/react';
import { Chipcolor } from '../types/Component';

type Props = {
    chips: string[],
    title: string,
}

const RandomChips = ({ title , chips}: Props) => {
  const color: Chipcolor[] = ['primary', 'secondary', 'success', 'warning', 'danger', 'default', undefined];
  return (
    <>
        <hr/>
            {title} : &nbsp;  &nbsp;
            { chips.map((keyword) => 
                <>
                    <Chip key={keyword} color={color[Math.floor(Math.random() * color.length)] as Chipcolor  }>{keyword}</Chip>
                    &nbsp; &nbsp;
                </>
            )}
        <hr/>
    </>
  )
}

export default RandomChips