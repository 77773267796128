import React, { useState, useEffect } from 'react';
import { getData } from '../util/fetchData';
import  {SiteMap} from '../types/Result';
import { Link } from "@nextui-org/react";
import TableComponent from './TableComponent';
import { create } from 'domain';
import { KeyValueObject } from '../types/Component';
type Props = {}

const CrawlList = (props: Props) => {
    const [siteMap, setSiteMaps] = useState<SiteMap[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [error, setError] = useState<string | null>(null)
    const getSiteMapPage = async (pageNo: number) => {
        setError("")
        try {
            setIsLoading(true)
            const responseData = await getData(`/api/sitemaps/?page=${pageNo}`);
            setSiteMaps(responseData as SiteMap[]);
            setIsLoading(false);
        } catch (error) {
            console.log('Error fetching dream data:', error); // Log the error message
            setError('Error on getting the data');
        } finally {
            setIsLoading(false); // Ensure loading state is reset regardless of success or error
        }
    };

    useEffect(() => {
        getSiteMapPage(page);
    }, [page]);


    const createCellElement = (item: SiteMap, uid: string) => {
        if( uid === 'site_name')
            return <Link isExternal anchorIcon href={`/sitemaps/${item.sitemap_url}`}>{item.site_name}</Link>
        else if ( uid === 'sitemap_url')
            return <Link isExternal anchorIcon href={`/sitemaps/${item.sitemap_url}`}>{item.sitemap_url}</Link>
        else if ( uid === 'article_count')
            return <Link isExternal anchorIcon href={`/sitemaps/${item.sitemap_url}`}>{item.article_count}</Link>
        else
            return '';

    }

    const tableHeader = [
        {
            name: 'Site Name',
            uid: 'site_name'
        },
        {
            name: 'Site Map Url',
            uid: 'sitemap_url'
        },
        {
            name: 'Delete',
            uid: 'enable_delete'
        },
    ]
  return (
    <>
        { siteMap &&
            <TableComponent data={siteMap} uniqueKey='sitemap_url' tableColumns={tableHeader} pageChange={setPage} createCellElement={createCellElement} />
        }
    </>
  )
}

export default CrawlList