import React from 'react'
import DreamComponent from '../components/DreamComponent';

type Props = {}

const Home = (props: Props) => {
  return (
    <DreamComponent/>
  )
}

export default Home;