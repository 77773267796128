import React from 'react';
import { Dream } from '../types/Result';
import { Card, CardBody, CardFooter, CardHeader } from '@nextui-org/react';
import Markdown from 'react-markdown'
import RandomChips from './RandomChip';

const DreamBox: React.FC<Dream> = ({ dream, explanation, keywords}) => {

  return (
    <Card>
        <CardHeader>
            <h2>{dream}</h2>
        </CardHeader>
        <CardBody className='markdown'>
            <Markdown>{explanation}</Markdown>
        </CardBody>
        <CardFooter>
           <RandomChips title="Keywords" chips={keywords} />
        </CardFooter>
    </Card>
  )
};

export default DreamBox;
