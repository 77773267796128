import React, { useState, useEffect } from 'react';
import { Card, CardBody, Input, Button, CardFooter, Textarea, Divider, Image, CardHeader, Link } from '@nextui-org/react';
import  '../style/dream.scss';
import { postData } from '../util/fetchData';
import {Article, Result} from '../types/Result';
import DreamResult from './DreamResult';

interface DreamComponentProps {
  placeholder?: string; // Optional placeholder text for the input
}

const DreamComponent: React.FC<DreamComponentProps> = ({
  placeholder = 'Search...',
}) => {
  const [dreamTerm, setDreamTerm] = React.useState('');
  const [result, setResultData] = useState<Result | null>(null);
  const [relatedContent, setRelatedContentData] = useState<Article[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null)

  const { keywords = [] } = result || {};
  const handleSearch = async () => {
    setError("")
    try {
      setIsLoading(true)
      const responseData = await postData(`/api/dreams/?restrict_bypass=True&mark_up=True`, { dream: dreamTerm });
      setResultData(responseData);
      setIsLoading(false);
    } catch (error) {
      console.log('Error fetching dream data:', error); // Log the error message
      setError('Error on getting the data');
    } finally {
      setIsLoading(false); // Ensure loading state is reset regardless of success or error
    }
  };

  const getRelatedContent = async ( )=> {
    try {
      setIsLoading(true)
      const responseData = await postData(`/api/crawl/related`, { 'search': keywords });
      setRelatedContentData(responseData);
      setIsLoading(false);
    } catch (error) {
      console.log('Error fetching related article:', error); // Log the error message
      setError('Error on getting the related article');
    } finally {
      setIsLoading(false); // Ensure loading state is reset regardless of success or error
    }
  }

  useEffect( () => {
    if( keywords?.length > 0) {
        getRelatedContent();
    }
  }, [keywords]);

  return (
    <section className="dream-section">
      <Card className="dream-box">
        <CardHeader className="flex gap-3">
          <div className="flex flex-col">
            <h2 className="text-md">Enter Dream</h2>
          </div>
        </CardHeader>
        <Divider/>
        <CardBody>
          <Textarea
            value={dreamTerm}
            onChange={(e) => setDreamTerm(e.target.value)}
            placeholder={placeholder}
            fullWidth
            onClear={() => setDreamTerm('')} // Clear search term on clear button click
          />
        </CardBody>
        <Divider/>
        <CardFooter className='justify-center'>
          <Button color="primary" onClick={handleSearch} variant="solid" isLoading={isLoading}>
            Search
          </Button>
        </CardFooter>
        <p className='text-center'> {error ? error : ''}</p>
      </Card>

      {
        result ?<DreamResult result={result} relatedContent={relatedContent} /> : null
      }

    </section>
  );
};

export default DreamComponent;

